import apiClient from '@/services/axios'

const create = async (data) => {
  const res = await apiClient.post('/testLab/dashboard', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  create,
}
